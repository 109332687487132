/* _project.scss */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700 !important;
 }


 .light-text {
  color: $grey-medium !important;
  p {
    color: $grey-medium;
  }
 }

 .menu--mobile-wrapper {
  top: 10px;
  position: fixed;
  width: 100%;
  z-index: 9999;
 }

 .menu--mobile-items {
  top: 60px;
  z-index: 9999;
  position: fixed;
 }

 .content-group-tabs__buttons-wrapper {
   top: 35px;
   @include mq($from: desktop) {
    top: $header-height-desktop;
   }
}

.column:has(.menu--desktop__mobile) {
  justify-content: flex-start !important;
  @include mq($from: tablet) {
    justify-content: center !important;
  }
}

.row:has(.image--parallax) {
  margin-top: 0 !important;
  padding: 0;
}

.image--parallax {
  height: 65vh;
  .image__children {
    top: 55%;
    min-width: 300px;

    @include mq($from: tablet) {
      top: 50%;
     min-width: none;
    }

    .hero-title h1 {
      line-height: 1.1;
      font-size: $h1-font-size - 1rem !important;
      margin: 0;
      @include mq($from: desktop) {
        font-size: $h1-font-size !important;
      }
    }
    .hero-subtitle {
      padding: 15px ;
      @include mq($from: desktop) {
        padding: 20px 25px;
      }
      p {
        margin-top: 0;
      }
    }
  }
}

